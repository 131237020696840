import { Header, HeaderBox, Overlay, Page } from "../manage/styles";
// import FormInput from "../../input";
import { getValue } from "../functions";
import { GetIcon } from "../../../../icons";
import {
  CloseButton,
  DataItem,
  DisplayInfo,
  Logo,
  TabContainer,
  Td,
  Title,
  TrBody,
} from "./styles";
import Tabs from "../../tab";
import React, { useCallback, useEffect, useState } from "react";
import { RowContainer } from "../../../styles/containers/styles";
// import ListTable from "../list";

import { ProfileImage } from "../styles";
import ImagePopup from "../image";
import { CustomPageTemplate } from "../custom";
import { Title as TitleElement } from "../../elements";
import { noimage, mobLogo } from "../../../../images";
import { dateTimeFormat } from "../../functions/date";
import CrudForm from "../create";
// import CrudForm from "../create";

export const DisplayInformations = ({ attributes, data, formMode }) => {
  const [showImage, setShowImage] = useState(false);

  return (
    <DisplayInfo>
      <TrBody className={formMode}>
        {attributes.map((attribute, index) => {
          if (attribute.view) {
            try {
              const itemValue =
                attribute.collection?.length > 0 &&
                attribute.showItem?.length > 0
                  ? data[attribute.collection][attribute.showItem]
                  : data[attribute.name];
              // if (attribute.type === "image") {
              //   return "";
              // }
              return (
                <Td key={index}>
                  <Title>
                    {attribute.icon?.length > 0 && (
                      <GetIcon icon={attribute.icon}></GetIcon>
                    )}
                    {attribute.label}
                  </Title>
                  {attribute.type === "image" ? (
                    <DataItem>
                      {getValue(attribute, itemValue, true, false, (src) => {
                        setShowImage(src);
                      })}
                    </DataItem>
                  ) : (
                    <DataItem>{getValue(attribute, itemValue, true)} </DataItem>
                  )}
                </Td>
              );
            } catch (error) {
              return (
                <Td key={index}>
                  <Title>{attribute.label}</Title>
                  <DataItem>{`--`} </DataItem>
                </Td>
              );
            }
          }
          if (attribute.type === "title") {
            return (
              <Td key={index} className="span">
                <TitleElement
                  line={false}
                  icon={attribute.icon}
                  title={attribute.title}
                ></TitleElement>
              </Td>
            );
          }
          return null;
        })}
      </TrBody>
      {showImage && (
        <ImagePopup
          onClose={() => setShowImage(null)}
          src={showImage.src}
        ></ImagePopup>
      )}
    </DisplayInfo>
  );
};
const ListTable = React.lazy(() => import("../list"));
// const CrudForm = React.lazy(() => import("../create"));
const Popup = ({
  showInfoType = "view",
  editData,
  customProfileSource,
  profileImage,
  isSingle = false,
  popupMode = "medium",
  showInfo,
  popupMenu,
  formMode,
  selectedMenuItem,
  viewMode,
  themeColors,
  openData,
  setLoaderBox,
  setMessage,
  closeModal,
  itemTitle,
  updatePrivilege,
  isEditingHandler,
  udpateView,
  parentName,
  parentIcon,
  parents = {},
}) => {
  const titleValue =
    (itemTitle.collection?.length > 0
      ? openData?.data?.[itemTitle.collection]?.[itemTitle.name] ?? ""
      : openData?.data?.[itemTitle.name]) || "Please update the itemTitle.";
  // const MemoizedListTable = React.memo(ListTable);

  const tabHandler = useCallback(() => {
    const tempTab = openData.actions
      .filter((item) =>
        [
          "subList",
          "subTabs",
          "subItem",
          "custom",
          "information",
          "title",
        ].includes(item.type)
      )
      .map((item, index) => ({
        name: `${item.id}-${index}`,
        title: item.title,
        icon: item.icon,
        type: item.type,
        css: item.type === "information" ? "info" : "",
        // Only set 'element' if there are no tabs
        element: item.tabs?.length ? null : item.type === "custom" ? (
          <CustomPageTemplate
            openData={openData}
            {...item}
            themeColors={themeColors}
            setLoaderBox={setLoaderBox}
            setMessage={setMessage}
            content={item.content}
          ></CustomPageTemplate>
        ) : item.type === "information" ? (
          <CrudForm {...editData} css="plain head-hide info"></CrudForm>
        ) : (
          <ListTable
            icon={item.icon}
            showInfo={item.showInfo ?? true}
            viewMode={item.type ?? "subList"}
            setMessage={setMessage}
            setLoaderBox={setLoaderBox}
            parentReference={item?.params?.parentReference}
            referenceId={openData?.data?._id}
            attributes={item.attributes}
            {...item.params}
            parents={{
              ...parents,
              [item?.params?.parentReference]: openData?.data?._id,
            }}
          ></ListTable>
        ), // If there are tabs, we set element to null
        tabs: item.tabs
          ?.filter((item) =>
            [
              "subList",
              "subTabs",
              "subItem",
              "custom",
              "information",
              "title",
            ].includes(item.type)
          )
          .map((tabItem, index2) => ({
            name: `${tabItem.id}-${index}-${index2}`,
            title: tabItem.title,
            type: tabItem.type,
            icon: tabItem.icon,
            css: tabItem.type === "information" ? "info" : "",
            element: tabItem.tabs?.length ? null : tabItem.type === "custom" ? (
              <CustomPageTemplate
                openData={openData}
                {...tabItem}
                themeColors={themeColors}
                setLoaderBox={setLoaderBox}
                setMessage={setMessage}
                content={tabItem.content}
              ></CustomPageTemplate>
            ) : tabItem.type === "information" ? (
              <CrudForm {...editData} css="plain head-hide info"></CrudForm>
            ) : (
              <ListTable
                icon={tabItem.icon}
                showInfo={tabItem.showInfo ?? true}
                viewMode={tabItem.type ?? "subList"}
                setMessage={setMessage}
                setLoaderBox={setLoaderBox}
                parentReference={tabItem?.params?.parentReference}
                referenceId={openData?.data?._id}
                attributes={tabItem.attributes}
                {...tabItem.params}
                parents={{
                  ...parents,
                  [item?.params?.parentReference]: openData?.data?._id,
                }}
              ></ListTable>
            ),
            tabs: tabItem.tabs
              ?.filter((item) =>
                [
                  "subList",
                  "subTabs",
                  "subItem",
                  "custom",
                  "information",
                  "title",
                ].includes(item.type)
              )
              .map((subTabItem, index3) => ({
                name: `${subTabItem.id}-${index}-${index2}-${index3}`,
                title: subTabItem.title,
                type: subTabItem.type,
                icon: subTabItem.icon,
                css: subTabItem.type === "information" ? "info" : "",
                element:
                  subTabItem.type === "custom" ? (
                    <CustomPageTemplate
                      openData={openData}
                      {...subTabItem}
                      themeColors={themeColors}
                      setLoaderBox={setLoaderBox}
                      setMessage={setMessage}
                      content={subTabItem.content}
                    ></CustomPageTemplate>
                  ) : subTabItem.type === "information" ? (
                    <CrudForm
                      {...editData}
                      css="plain head-hide info"
                    ></CrudForm>
                  ) : (
                    <ListTable
                      icon={subTabItem.icon}
                      showInfo={subTabItem.showInfo ?? true}
                      viewMode={subTabItem.type ?? "subList"}
                      setMessage={setMessage}
                      setLoaderBox={setLoaderBox}
                      parentReference={subTabItem?.params?.parentReference}
                      referenceId={openData?.data?._id}
                      attributes={subTabItem.attributes}
                      {...subTabItem.params}
                      parents={{
                        ...parents,
                        [item?.params?.parentReference]: openData?.data?._id,
                      }}
                    ></ListTable>
                  ),
              })),
          })),
      }));
    showInfo &&
      tempTab.unshift({
        name: `information-${openData.data._id}`,
        title: `${parentName} Details`,
        icon: "info",
        css: "info",
        element:
          showInfoType === "edit" ? (
            <CrudForm {...editData} css="plain head-hide info"></CrudForm>
          ) : (
            <TabContainer className="tab">
              {/* <Head className="sticky">
                <div>
                  {updatePrivilege && (
                    <More
                      theme={themeColors}
                      onClick={(event) => {
                        event.stopPropagation();
                        isEditingHandler(openData?.data, udpateView, titleValue);
                      }}
                    >
                      <GetIcon icon={"edit"} />
                    </More>
                  )}
                </div>
              </Head> */}
              <DisplayInformations
                formMode={formMode}
                attributes={openData.attributes}
                data={openData.data}
              />
            </TabContainer>
          ),
      });
    setTabs(tempTab);
  }, [
    showInfo,
    formMode,
    showInfoType,
    editData,
    parentName,
    setMessage,
    parents,
    setLoaderBox,
    openData,
    themeColors,
  ]);

  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    tabHandler();
  }, [tabHandler]);
  return (
    <Overlay className={isSingle ? "plain" : ""}>
      <Page
        className={`${isSingle ? "plain" : ""} ${
          popupMode ?? "medium"
        } popup-child`}
      >
        {!isSingle && (
          <Header className={`parent ${popupMenu}`}>
            <Logo src={mobLogo} alt="logo" />
            <HeaderBox className="header-data">
              <div>
                {profileImage ? (
                  <ProfileImage className="full">
                    <img
                      src={
                        openData?.data[profileImage]
                          ? (customProfileSource
                              ? ""
                              : process.env.REACT_APP_CDN) +
                            openData?.data[profileImage]
                          : noimage
                      }
                      onError={(e) => {
                        e.target.src = noimage; // Hide the image on error
                      }}
                      alt="Profile"
                    ></img>
                  </ProfileImage>
                ) : (
                  <GetIcon icon={parentIcon ?? selectedMenuItem.icon}></GetIcon>
                )}
                <div>
                  <span> {`${getValue(itemTitle, titleValue)}`}</span>
                  <span> {dateTimeFormat(openData?.data["startDate"])}</span>
                </div>
              </div>
              <CloseButton theme={themeColors} onClick={closeModal}>
                <GetIcon icon={"Close"} />
              </CloseButton>
            </HeaderBox>
          </Header>
        )}
        <RowContainer theme={themeColors} className="popup-data">
          {tabs.length > 0 && <Tabs popupMenu={popupMenu} tabs={tabs}></Tabs>}
        </RowContainer>
      </Page>
    </Overlay>
  );
};
export default Popup;
