import React, { lazy, Suspense } from "react";

// Lazy load all components
const Login = lazy(() => import("../../public/login"));
const Menu = lazy(() => import("../../project/pages/menu"));
const Franchise = lazy(() => import("../../project/pages/franchise"));
const Page404 = lazy(() => import("../../project/pages/page404"));
const UserType = lazy(() => import("../../project/pages/user/userType"));
const UserList = lazy(() => import("../../project/pages/user/userList"));
const Dashboard = lazy(() => import("../../project/pages/dashboard"));
const Admin = lazy(() => import("../../project/pages/franchise/admin"));
const ErrorLog = lazy(() => import("../../project/pages/errorLog"));
const Elements = lazy(() => import("../pages/settings/elements"));
const Settings = lazy(() => import("../../core/settings/index.js"));

// const ActivityTracker = lazy(() =>
//   import("../../project/pages/settings/activityTracker.js")
// );
const ACEA = lazy(() => import("../../project/pages/acea"));
const API1 = lazy(() => import("../../project/pages/api1"));
const API2 = lazy(() => import("../../project/pages/api2"));
const Category = lazy(() => import("../../project/pages/category"));
const CountryCode = lazy(() => import("../../project/pages/countryCode"));
const CountryOilGrade = lazy(() =>
  import("../../project/pages/countryOilGrade")
);
const CountryVersion = lazy(() => import("../../project/pages/countryVersion"));
const Grade = lazy(() => import("../../project/pages/grade"));
const Packaging = lazy(() => import("../../project/pages/packaging"));
const Product = lazy(() => import("../../project/pages/product"));
const Print = lazy(() => import("../../project/pages/print"));
const Scanner = lazy(() => import("../../project/pages/scanner"));
const PartNumber = lazy(() => import("../../project/pages/partNumber"));
const CartoonPackage = lazy(() => import("../../project/pages/cartoonPackage"));
const ActivityLog = lazy(() => import("../../project/pages/activityLog"));

const RenderPage = (page, key, user, privileges) => {
  const renderComponent = (Component) => (
    <Suspense fallback={<div>Loading...</div>}>
      <Component key={key} {...privileges} />
    </Suspense>
  );

  switch (page) {
    case "login":
      return renderComponent(Login);
    // case "projects":
    //   return renderComponent(Projects);
    case "menu":
      return renderComponent(Menu);
    case "franchise":
      return renderComponent(Franchise);
    case "user-role":
      return renderComponent(UserType);
    case "user":
      return renderComponent(UserList);
    case "dashboard":
      return renderComponent(Dashboard);
    case "admin":
      return renderComponent(Admin);
    case "error-log":
      return renderComponent(ErrorLog);
    case "elements":
      return renderComponent(Elements);
    case "settings":
      return renderComponent(Settings);

    // case "log":
    //   return renderComponent(ActivityTracker);
    case "acea":
      return renderComponent(ACEA);
    case "api1":
      return renderComponent(API1);
    case "api2":
      return renderComponent(API2);
    case "category":
      return renderComponent(Category);
    case "country-code":
      return renderComponent(CountryCode);
    case "country-oil-grade":
      return renderComponent(CountryOilGrade);
    case "country-version":
      return renderComponent(CountryVersion);
    case "grade":
      return renderComponent(Grade);
    case "packaging":
      return renderComponent(Packaging);
    case "product":
      return renderComponent(Product);
    case "print":
      return renderComponent(Print);
    case "scanner":
      return renderComponent(Scanner);
    case "part-number":
      return renderComponent(PartNumber);
    case "cartoon-package":
      return renderComponent(CartoonPackage);
    case "log":
      return renderComponent(ActivityLog);

    default:
      return renderComponent(Page404);
  }
};
export default RenderPage;
