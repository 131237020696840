import React from "react";
import { Bar, Label } from "../styles";

const CustomLabel = ({
  name,
  label,
  required,
  sublabel = "",
  error = "",
  className = "",
  percetage = false,
  percetageValue = "",
  leftContent = "",
  rightContent = "",
}) => {
  const hasError = error?.length;
  const valueIsEmpty = label?.toString().length > 0;
  return valueIsEmpty && percetage ? (
    <React.Fragment>
      <Label
        htmlFor={name}
        className={`percentage ${className} ${hasError ? "error" : ""}`}
      >
        <span>{label}</span>
        <span>{percetageValue}%</span>
      </Label>
      <Bar>
        <div style={{ width: percetageValue + "%" }}></div>
      </Bar>
      <Label
        htmlFor={name}
        className={`percentage small ${className} ${hasError ? "error" : ""}`}
      >
        <span>{leftContent}</span>
        <span>{rightContent}</span>
      </Label>
    </React.Fragment>
  ) : (
    <Label htmlFor={name} className={`${className} ${hasError ? "error" : ""}`}>
      <span>{label}</span>
      {required && <i>*</i>}
      {sublabel && <span className="sublabel">({sublabel})</span>}
    </Label>
  );
};

export default CustomLabel;
